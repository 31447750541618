import React, { useState, useContext } from 'react';
import $ from 'jquery';
import Cookies from './Cookies';
import { AppContext } from './App';

function Login(props) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const context = useContext(AppContext);

    function closeLogin() {
        props.doHideLogin();
    }
    function handleLogin(e) {
        e.preventDefault();
        setMessage('');
        if (email === '') {
            setMessage('Please enter an email or user name.');
            return true;
        }

        $.post("https://psiberneticgames.com/login.php", { email: email, password: password })
            .done(function (data) {
                if (data[1] === 'OK') {
                    props.doLoginClick();
                    Cookies.create("username", data[2], 7);
                    Cookies.create("userid", data[3], 7);
                    Cookies.create("token", data[4], 7);
                    context.doLogin();
                }
                else {
                    setMessage(data[1]);
                }
            })
            .fail(function (data) {
                console.log(data);
                setMessage('An unexpected error occurred processing your request. Check your network connection and try again.');
            });

        return false;
    }

    return (
        <div className="modal">
            <div className="modal-content center-child">
                <button onClick={closeLogin} id='close-modal-button'>X</button>
                <h2>Login</h2>
                <form onSubmit={handleLogin}>
                    <div class='form-field'>
                        <label>Email:</label>
                        <input className="login-reg" placeholder="Email..." type="email" id="email" name="email" value={email} onChange={(e) => { setEmail(e.target.value) }} />
                    </div>
                    <div class='form-field'>
                        <label>Password:</label>
                        <input className="login-reg" placeholder="Password..." type="password" id="password" name="password" value={password} onChange={(e) => { setPassword(e.target.value) }} />
                    </div>
                    <button onClick={handleLogin}>Login</button>
                    <button onClick={props.doShowRegisterClick}>Register</button>
                </form>
                <p>{message}</p>
            </div>
        </div>
    )
}

export default Login;