import React from 'react';
import { Link } from 'react-router-dom';
import { IoIosMail, IoIosPaper } from "react-icons/io";

function Support() {
    return (
        <main id='content'>
            <h1>Support</h1>
            <section className='container header'>
                <h2 className='center white'>Our team is here to help</h2>
                <span className='tagline white'>98% of our requests are answered within 24 hours.</span>
                <span className='tagline white'>Support is unlimited and FREE!</span>
            </section>
            <section className='buffer'></section>
            <section id='support-block' className='container header'>
                <span className='white tagline'>Need assistance?</span>
                <div id='support-section'>
                    <div className='support-container'>
                        <Link to='/faq'><IoIosPaper className='tagline blue large' /></Link>
                        <Link to='/faq'><span className='tagline blue'>Help Center</span></Link>
                    </div>
                    <div className='support-container'>
                        <IoIosMail className='tagline blue large' />
                        <span className='tagline blue'>support@psiberneticgames.com</span>
                    </div>
                </div>
            </section>
        </main>);
}

export default Support;