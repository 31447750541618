import React, { useState, useEffect } from 'react';

function ImageLoader(props) {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let img = new Image();

        img.onload = () => {
            setIsLoading(false);
        }

        img.src = props.src;
    }, [props.src]);
    return (isLoading === false ?
        <img src={props.src} alt={props.alt} className='fade-in' />
        :
        <img src={props.thumb} alt={props.alt} className='blur' />);
}

export default ImageLoader;
