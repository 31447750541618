import React from 'react';
import $ from 'jquery';
import {FaCaretSquareDown} from 'react-icons/fa';

function toggleAnswer(id) {
    $('#' + id).slideToggle(400);
    $('#' + id + '-toggle').toggleClass('rotate');
    $('#' + id + '-toggle').toggleClass('rotate-reset');
}
function Faq() {
    return (
        <div id="content">
            <h1>Frequently Asked Questions</h1>
            <section className='container'>
                <h2>Quintessential Duels</h2>
                <article className='question-container'>
                    <p className='question'><span>What are the system requirements for Quintessential Duels?</span><span className='question-toggle' onClick={() => toggleAnswer("systemrequirements")}><FaCaretSquareDown id='systemrequirements-toggle' className='rotate-reset' /></span></p>
                    <p id='systemrequirements' className='answer'>The system requirements for Quintessential Duels on PC are Windows 7/8.1/10, 4GB RAM, 250MB disk space and a graphics card that supports Shader Model 4.0.</p>
                </article>
                <article className='question-container'>
                    <p className='question'><span>Is Quintessential Duels supported on a Mac?</span><span className='question-toggle' onClick={() => toggleAnswer("macsupport")}><FaCaretSquareDown id='macsupport-toggle' className='rotate-reset' /></span></p>
                    <p id='macsupport' className='answer'>Currently Quintessential Duels is only supported on PC and Android. We are researching having Quintessential Duels available on Mac and iOS.</p>
                </article>
                <article className='question-container'>
                    <p className='question'><span>What is the same rule?</span><span className='question-toggle' onClick={() => toggleAnswer("samerule")}><FaCaretSquareDown  id='samerule-toggle' className='rotate-reset' /></span></p>
                    <p id='samerule' className='answer'>The same rule is when the value of two, or more, adjecnt sides of a card match. When they do you capture the adjacent cards and then do a combination on those cards capturing those cards if the requirements are met. It is a powerful way to alter the board. Watch for opportunities to use it to your advantage.</p>
                </article>
                <article className='question-container'>
                    <p className='question'><span>What is the plus rule?</span><span className='question-toggle' onClick={() => toggleAnswer("plusrule")}><FaCaretSquareDown  id='plusrule-toggle' className='rotate-reset' /></span></p>
                    <p id='plusrule' className='answer'>The pluse rule is similar to the same rule. The plus triggers when the sum of two, or more, adjecnt sides add up to the same value. When they do you capture those adjacent cards and then do a combination on those cards capturing those cards if the requirements are met. It is a powerful way to alter the board. Watch for opportunities to use it to your advantage.</p>
                </article>
            </section>
        </div>
    );
}

export default Faq;