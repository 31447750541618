import React, { useState } from 'react';
import $ from 'jquery';
import Cookies from "./Cookies";
import { Link } from "react-router-dom";

function Register(props) {
    const [emailReg, setEmailReg] = useState('');
    const [passwordReg, setPasswordReg] = useState('');
    const [passwordConf, setPasswordConf] = useState('');
    const [message, setMessage] = useState('');
    const [user, setUser] = useState('');

    function ValidateEmail(mail) {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail)) {
            return (true)
        }
        setMessage("You have entered an invalid email address!");
        return (false)
    }

    const closeRegistration = () => {
        props.doHideRegistration();
    }

    const doRegisterClick = (e) => {
        e.preventDefault();
        setMessage('');

        if (!ValidateEmail(emailReg)) {
            return;
        }

        if (passwordReg !== passwordConf) {
            setMessage('Password does not match confirmation password.');
            return;
        }

        $.post("https://psiberneticgames.com/api/account/register.php", { user: user, email: emailReg, password: passwordReg, confirm: passwordConf })
            .done(function (data) {
                if (data[1] === 'OK') {
                    props.doHideRegistration();
                    Cookies.create("username", data[2], 7);
                    Cookies.create("userid", data[3], 7);
                    Cookies.create("token", data[4], 7);
                    props.doLogin();
                }
                else {
                    setMessage(data[1]);
                }
            })
            .fail(function (data) {
                console.log(data);
                setMessage('An unexpected error occurred processing your request. Check your network connection and try again.');
                console.log(data);
            });
    }

    return (
        <div className="modal">
            <div className="center-child modal-content">
                <button onClick={closeRegistration} id='close-modal-button'>X</button>
                <h2>Register</h2>
                <form >
                    <div class='form-field'>
                        <label>Email:</label>
                        <input className="login-reg" placeholder="Email..." type="email" id="emailReg" name="emailReg" value={emailReg} onChange={(e) => { setEmailReg(e.target.value) }} />
                    </div>
                    <div class='form-field'>
                        <label>Username:</label>
                        <input className="login-reg" placeholder="Username..." type="text" id="user" name="user" value={user} onChange={(e) => { setUser(e.target.value) }} />
                    </div>
                    <div class='form-field'>
                        <label>Password:</label>
                        <input className="login-reg" placeholder="Password..." type="password" id="passwordReg" name="passwordReg" maxLength="16" vvalue={passwordReg} onChange={(e) => { setPasswordReg(e.target.value) }} />
                    </div>
                    <div class='form-field'>
                        <label>Confirm:</label>
                        <input className="login-reg" placeholder="Retype password..." type="password" id="passwordConf" name="passwordConf" maxLength="16" vvalue={passwordConf} onChange={(e) => { setPasswordConf(e.target.value) }} />
                    </div>
                    <div className="btn-container">
                        <button onClick={doRegisterClick}>Register</button>
                        <button type="button" onClick={props.showLogin}>Login</button>
                    </div>
                    <p>By clicking register you agree to our <Link className='grey' to='/terms' target='_blank'>Terms of Service</Link> and <Link className='grey' to='/privacy' target='_blank'>Privacy Policy</Link>.</p>
                </form>
                <p>{message}</p>
            </div>
        </div>
    );
}

export default Register;