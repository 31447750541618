import React from 'react';
import Cookies from './Cookies';
import $ from 'jquery';

function DismissCookies() {
    Cookies.create('accept-cookies', 'accepted', 365);
    $('#cookies-container').hide();
}

function CookiesContainer() {
    if (Cookies.read('accept-cookies') === null) {
        return (    
                <div id='cookies-container'>
                    <div className='white'>
                        <div>This site uses cookies to enhance your user experience. By clicking Accept you accept our use of cookies.</div>
                        <span className='full-width'><button onClick={() => DismissCookies()} className='center'>Accept</button></span>
                    </div>
                </div>
        )
    }

    return <span></span>
}

export default CookiesContainer;