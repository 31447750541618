import React, { useState } from 'react';
import ImageLoader from './ImageLoader';

function NewsItem(props) {
    const [toggleParagraph, setToggleParagraph] = useState(true);

    return (
        <div>
            <span className='news-date blue'>{props.date}</span>
            {
                !toggleParagraph ?
                    <section className='toggle'>
                        <article className='white showMore'>
                            <div dangerouslySetInnerHTML={createMarkup(props.body)} />
                            <ImageLoader src={props.image} thumb={props.thumb} alt={props.alt} />                        
                        </article>
                        <span className='toggleButton' onClick={() => setToggleParagraph(true)}>Show less...</span>
                    </section>
                    :
                    <section className='toggle'>
                        <article className='white showLess'>
                            <div dangerouslySetInnerHTML={createMarkup(props.body)} />
                            <ImageLoader src={props.image} thumb={props.thumb} alt={props.alt} />                        
                        </article>
                        <span className='toggleButton' onClick={() => setToggleParagraph(false)}>Show more...</span>
                    </section>
            }
        </div>
    )
}
function createMarkup(innerHtml) {
    return {__html: innerHtml};
}
 
export default NewsItem;