import React, { useContext } from 'react';
import Cookies from './Cookies';
import { WebSiteContext } from './Website';
import { AppContext } from './App';
import { GoThreeBars } from 'react-icons/go';
import $ from 'jquery';
import { Link } from 'react-router-dom';

function Banner() {

    function toggleMobile() {
        $('#mobile-nav').slideToggle(200);
    }

    return (
        <div id="nav-container">
            <header aria-label="Header">
                <div id="nav-links">
                    <a href="#banner" aria-label="Navigation" className="hidden grey">Navigation</a>
                    <a href="#content" aria-label="Content" className="hidden grey">Content</a>
                    <a href="#footer" aria-label="Footer" className="hidden grey">Footer</a>
                </div>
                <nav id="banner">
                    <div className="container">
                        <div className="nav-bar">
                            <span className="logo">Psibernetic Games</span>
                            <Link to='/' className='nav-button'>Games</Link>
                            <Link to='/support' className='nav-button'>Support</Link>
                            <Link to='/news' className='nav-button'>News</Link>
                            <Link to='/about' className='nav-button'>About</Link>
                            {/* <Link to='/about' className='nav-button'>About</Link> */}
                            {/* <span></span> */}
                            <span></span>
                            <Buttons />
                        </div>
                    </div>
                </nav>
                <div id="mobile-banner">
                    <div className="container">
                        <div className='nav-mobile'>
                            <span className='logo'>Psibernetic Games</span>
                            <span></span>
                            <button id='mobile-button' name='mobile-button' aria-label='toggle mobile menu' onClick={toggleMobile}><GoThreeBars /></button>
                        </div>
                    </div>
                </div>
            </header>
        </div>
    );
}

function Buttons() {
    let data = useContext(WebSiteContext);
    const context = useContext(AppContext);

    //setIsLoggedIn(username !== null),[use];

    function LogOff() {
        Cookies.erase('username');
        Cookies.erase('userid');
        context.doLogOff();
    }

    function doRepaint() {
        data.props.showLogin();
    }

    if (context.isLoggedIn) {
        return (
            <span>
                <button className='margin-right'>Profile</button>
                <button onClick={LogOff}>Log Off</button>
            </span>
        )
    }

    return (
        <span>
            <button className='margin-right' onClick={doRepaint}>Login</button>
            <button onClick={data.props.showRegister}>Register</button>
        </span>);
}

export { Buttons };
export default Banner;