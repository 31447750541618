import React, { useState } from 'react';
import Login from './Login';
import Website from './Website'
import Cookies from './Cookies';
import './style.css';

const AppContext = React.createContext();

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(Cookies.read('username') !== null);
  const [isLogin, setIsLogin] = useState(false);
  const [isRegister, setIsRegister] = useState(false);
  const [mobile, setMobile] = useState(false);

  const toggleMobile = () => {
    setMobile(!mobile);
  }

  const doLoginClick = () => {
    setIsLogin(false);
    setIsRegister(false);
    handleLogin();
  }

  const doShowGame = () => {
    setIsLogin(false);
    setIsRegister(false);
  }

  const doShowLogin = () => {
    setIsLogin(true);
    //setIsRegister(false);
  }

  const doHideLogin = () => {
    setIsLogin(false);
  }

  const doHideRegistration = () => {
    setIsRegister(false);
  }

  const doShowRegisterClick = () => {
    setIsLogin(false);
    setIsRegister(true);
  }

  const registrationComplete = () => {
    setIsLogin(false);
    setIsRegister(false);
  }

  const doLogin = () => {
    setIsLoggedIn(true);
  }

  const doLogOff = () => {
    setIsLoggedIn(false);
  }

  const handleLogin = () => {
  }
  if (isLogin) {
    return (
      <AppContext.Provider value={{ doLogin: doLogin, doLogOff: doLogOff, isLoggedIn: isLoggedIn }} >
        <Website showLogin={doShowLogin} showRegister={doShowRegisterClick} playGame={doShowGame} toggleMobile={toggleMobile} mobile={mobile} />
        <Login doHideLogin={doHideLogin} doLoginClick={doLoginClick} doShowRegisterClick={doShowRegisterClick} />
      </ AppContext.Provider>
    )
  }

  return (
    <AppContext.Provider value={{ doLogin: doLogin, doLogOff: doLogOff, isLoggedIn: isLoggedIn }} >
      <Website displayLogin={isLogin} doLogin={doLogin} showRegister={doShowRegisterClick} displayRegister={isRegister} doHideRegistration={doHideRegistration} complete={registrationComplete} showLogin={doShowLogin} />
    </AppContext.Provider>
  )
}

export { AppContext };
export default App;