import React from 'react';
import ImageLoader from './ImageLoader';

function About() {
    return (
        <main id='content' className='container'>
            <div><h1 id='about' className='center white'>About Psibernetic Games</h1></div>
            <section className='header'>
                <h2 className='center white'>- Our Mission -</h2>
                <h3 className='center white'>To provide a quality and entertaining gaming experiences.</h3>            
            </section>
            <section>
                <div><h2 className='center white'>Our Leadership</h2></div>
                <div className='center thumbnail'><ImageLoader src='https://psiberneticgames.com/Content/cynthia.png' thumb='iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAMPSURBVDhPTZLLb9NYFMY/Xztp6CNNnQe0aopKSVpBoQGpIKEKJMQshhnNNggWbBD/CRv4A1iwRALKAoRY8JJQIIIZKAHaoqYptEmbpCFxYzsPx46vHXNT0IizuJtzf9855zuHw2+ReHRfKEnli2+Sby5alM4Gg8Eh0edTbNtaiExF70xPT9+ZiJ2yfn3H//B/Lx5P1mq1+UTi1UxuYwvnT88h5PdhYioKVZaxmMngxNzcotfrje+fPpHpMqT7fEw8jdq2nVxaWppZXl7B+bPn4BdFOBaFVCzCI/CIRQ4gu74xQylNfk0lo7vwYvK54DjOvFytBtMraQx5ffANDsCyLDRaBlq6DkM3QBwHJSZULpeCttWeT//7TCAcx13geS6WyayC2jYog5yOjY5tQeA5aC0dmmHAtjto1muQd2RY1Iyx/AVGk0t6u4VCoQi9TaHWdTx88hym3sL+4RDypRJcbjemJsdhWjZy2SxG9vrh8XguMZbMViWJqVEoag3qThmbm1lUqlWs5vMoSxXUGg2Ut4tILaexmcuCjQmOwyxrG6KyI4EXhF2DvH0enDxyGAfGx3H5n78xcyiCY9FxTEYmEf/jDNDpgJDukjiRmIahtho1cExNIAR7xQD8gRCOTBzEPr+Iw2NhhAJ+jIg+9LD2h4eHGQzwPK+SuiKlLEMD1TUwf7CjyggM9EPs70Xi9Tu2JgF7eIG53cFKbgu0VYepNdmeSIpoavUeZW5ahg6PW4DSqGN7Ow/LcWF0NAxP7yCMtgFZkZHdKkBmOamQg9lu3yP5b2u3qWmuCqwVNyvNs2nW8wUoFQWFzwpyn0oY7OuDpKrMzApsk6K4sb5qaNptcvXaTbNRa8RdPKcSx0b/Hjfep9fg6uUwFhMRPhpEMDSED18y7BwdZqxLLZWk+OyfcbNbCInP6fKxifAz5vy5pqaLeVZV7O3B6MgoOxYDbxcWkM5uMeGebzxH/rrx4OVil9uFu/FhbfP78cjYraZmVFp6eygcGAgN9gm8Zmjm9vfygtLUrlMbV+4mU4WfBPAD7GyK5TI4t0EAAAAASUVORK5CYII=' alt='Cynthia McMahon' /></div>
                <div className='center thumbnail'>Cynthia McMahon</div>
                <div className='center thumbnail'>Founder and CEO</div>
            </section>
            <section className='container'>
                <article className='about-article'>
                    <p>Psibernetic Games is an independent game development studio and the brainchild of Cynthia McMahon, founder and CEO. She is also the principal developer of all of the games developed by Psibernetic Game, as well as those in development. Though a completely virtual operation, our home is in the charming city of Toronto, Canada.</p>
                    <p>We specialize in collectible card games for mobile and the web, as well as role-playing games. Upcoming releases include the collectible card games Quintessential Duels and Lost Fate and the role-playing games Silver Prophet and Shadow Monsters.</p>
                </article>
            </section>
        </main>
    );
}

export default About;