import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Footer from './Footer';
import Games from './Games';
import Support from './Support';
import Banner from './Banner';
import Mobile from './Mobile';
import Terms from './Terms';
import Privacy from './Privacy';
import Register from './Register'
import Quintessential from './Quintessential';
import LostFate from './LostFate';
import Error from './Error';
import News from './News';
import About from './About';
import Faq from './faq';
import './style.css';
import SilverProphet from './SilverProphet';
import ShadowMonsters from './ShadowMonsters';
import CookiesContainer from './CookiesContainer';

const WebSiteContext = React.createContext();

function Website(props) {
    return (
        <WebSiteContext.Provider value={{ props: props }}>
            <Router>
                <Banner />
                <Mobile />
                <Switch>
                    <Route exact path='/'>
                        <Games />
                    </Route>
                    <Route path='/support'>
                        <Support />
                    </Route>
                    <Route path='/quintessential'>
                        <Quintessential />
                    </Route>
                    <Route path='/silverprophet'>
                        <SilverProphet />
                    </Route>
                    <Route path='/shadowmonsters'>
                        <ShadowMonsters />
                    </Route>
                    <Route path='/lostfate'>
                        <LostFate />
                    </Route>
                    <Route path='/privacy'>
                        <Privacy />
                    </Route>
                    <Route path='/terms'>
                        <Terms />
                    </Route>
                    <Route path='/news'>
                        <News />
                    </Route>
                    <Route path='/about'>
                        <About />
                    </Route>
                    <Route path='/faq'>
                        <Faq />
                    </Route>
                    <Route path='*'>
                        <Error />
                    </Route>
                </Switch>
                <Footer />
                <CookiesContainer />
                {
                    props.displayRegister && <Register doHideRegistration={props.doHideRegistration} complete={props.registrationComplete} showLogin={props.showLogin} doLogin={props.doLogin} />
                }
            </Router>
        </WebSiteContext.Provider>
    )
}

export { WebSiteContext };
export default Website;