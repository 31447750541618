import React from 'react';
import { FaFacebook, FaTwitter, FaLinkedin} from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Footer() {
    const d = new Date();

    return (
        <footer id="footer" aria-label="Footer">
            <p><span>Copyright &copy; {d.getFullYear()} - Psibernetic Games</span><span></span></p>
            <div className='footer-icons'><span></span><a href='https://www.facebook.com/Psibernetic-Games-100660312109839' target='_blank' rel='noopener noreferrer' aria-label='Facebook'><FaFacebook /></a> <a href='https://twitter.com/PsiberneticGms' target='_blank' rel='noopener noreferrer' aria-label='Twitter'><FaTwitter /></a> <a href='https://www.linkedin.com/company/psibernetic-games' target='_blank' rel='noopener noreferrer' aria-label='Linked In'><FaLinkedin /></a><span></span></div>
            <Link to='/privacy' className='grey'>Privacy Policy</Link> - <Link to='/terms' className='grey'>Terms of Service</Link>
        </footer>
    );
}

export default Footer;