import React from 'react';
import NewsItem from './NewsItem';
import NewsItemData from './Content/NewsItemData';

function News() {
    return (
        <main id='content' className='container'>
            <h1 className='center purple'>News</h1>
            {
                NewsItemData.NewsItems.slice(0).reverse().map((item) => {
                    return <NewsItem key={item.id} date={item.date} body={item.body} image={item.image} thumb={item.thumb} alt={item.alt} />
                })
            }
        </main>
    );
}

export default News;