const NewsItemData = {
    NewsItems: [
        {
            id: 0,
            date: 'March 17, 2021',
            body: '<p>Psibernetic Games is pleased to announce the upcoming release of its first game on April 15, 2021! Quintessential Duels is a casual collectible card game that is coming to PC and Android that is 100% free to play. It is based on the mini-game Triple Triad from Final Fantasy VIII and XIV. Instead of played on a three by three grid it is played on a five by five grid. Collect over one hundred different cards and battle 32 different decks.</p><p>Coming to itch.io and Google Play Store April 15, 2021!</p>',
            image: 'https://psiberneticgames.com/Content/QuintessentialRelease.png',
            thumb: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAIAAAC0SDtlAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAACxMAAAsTAQCanBgAAAGrSURBVChTDc/NTtRAHADwmf9MO51uux9dukpdWgtRPkQTNYiYGKPRg4nG6EVO3Lz4SLyAB48efAMPrgkGjQjKAcXNSs1uaFq6O52Zui/wS3742f0X/pXr9mBvt/RFKfI81UpKMbG5BahSGAzD3jn4y2wPEe40ffLg6eaofxitrkfn2zMWqtXMpYUoDDphd86gaOPW7Ukx/tU/wUCBGIzX8L2bT9Z4ws6G1WwMl9b+fPn4/jAJggs/f+xhYkoNQlFEXGy4CFuEe4R5i2J0ZFZiJgghWvcXlufnuitXr+1/+zwa/lOqwpRPbYRpVaoKEfwonrd1JpRu2dasUys9+wZ3t3ePev2BrAgijFgd0+0AawoBmDXIy4te2zJzkEt1J5djXZasFM83Ljfj4NP+CWV1ajWY3VAVNew6d1t4++7qwWnW6uIs1banF2N/TKIwV1vvvufUwcSSyJwILCVgYJj78CE5DR1+nIrHD8PC7Lx6g46TwVdIR7gxtRVieZqXRYZ0Mc0wt0ler8S9JE3P4Jxv3FluDwt4u6N6v2WmaqWC6QFhU2sK4ILjY8P8D19HpfjCXPIpAAAAAElFTkSuQmCC',
            alt: 'Quintessential Duels'
        },
        {
            id: 1,
            date: 'April 15, 2021',
            body: '<p>Psibernetic Games is pleased to announce the release of its first game, Quintessential Duels! Quintessential Duels is a casual collectible card game available from <a href="https://psibernetic-games.itch.io/quintessentila-duels" target="_blank" aria-label="Quintessentil Duels download link">Quintessential Duels on itch.io</a>. It is based on the mini-game Triple Triad from Final Fantasy VIII and XIV. Instead of played on a three by three grid it is played on a five by five grid. Collect over one hundred different cards and battle over thirty different decks. Coming soon to Google Play!</p>',
            image: 'https://psiberneticgames.com/Content/CoverImage.png',
            thumb: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAACxMAAAsTAQCanBgAAAOTSURBVDhPDZNJbBtlAIXnn7H/mfHMeLzEY+ySxcZJ2iiUKFUVClUrKIUDVRHiguDEHQRISL1y5MCJAxxQbyAh9UJKOERIETQ0TtQQqSklrbM5yXhfZl//mfmx9G7v8KTvvQeWf/np5x+/zd18f+nq0rO19e3Vx29++kFju6s2xFQ6zTIMQQBFUZvNMwjhVGkyl0sxDB2GlK654MTQtu7eWV/ftYN3xWlfqT04HMZmlxZON/24AGGcCcOIJEnPdYBPhMBNCFy5MF1ZqDB0lspfuTw/f7W5GXuwtXLOfkfts+f5G+3nAyESHUUjdci6XKBZ59kluf2Mx0nOzaqGghkiCCBVeu3Vf+t1NdQyw7md1nIYxyi0m8OnQ6uheU3L0wyvp7s9FPkDVwY4ZiHdJz1RyoUBTX3z/XcEDjx5F6M1lh1Art2xH1/Mf8SSqa73xA5kgZYsMAhEO5HJYI6EGXq8UipNVjCG4M7dH9gkH3nu4OC/tLJfZIKe6rb23gtDPJLpaCiyaFrwzinlqcV8vsDziVictq2o29HBia52uq2+rtue26jXctHgZPvh/m7ioCV7DpfLwlicDELO46SX518vl8sj5giFpun6fgBqLRky0HRsXVP6vnN4dKx0esrZET7ckjIiLwhZ6UXLdH5bMwrj16V8KUZRqXQSocC2HHBvp5rNphvy6c7vv9bWNyy5off1hJiYuDBx49ocX36FE5LT+ZwA4/W9R/f/Mlr6JZ7nHdc2DJ1q/f3n09r+0UZ1ElqzE9yttxfzUmY4sHr1vjd3ffXeYcL5h8T6o4cbNOU/qakBNaOoQ1UdYIypz778ojg+obRbxy1VZYtWtsTmU5feuKymi8bqCk1zfXRhZpyxzMAIkwfti4BkPM8BAEi5Avjwk4/d6h9MDPScyA0xwiM3MSal3lrMi2zy+XJVn73yQuUluTcbBRoJC7ZtdbuNUWxpaoYaM7rXivEsQ6ZpQBBEGOHI9w1VF0R+akq0AT05xgzlRnqybNgiQm67fdZs75vmkCBiVDE7xkNMa8gNCQmQiThOhRhi3DztN8+UHIyZIUrQRP+kQWPQ6chEZ48L3YiXWDZJ3br9dfu4KhBR4AXQixAFsiSV98MkTQqjN3S1wA96fUoEudFcjF6TsYdp39Q4CVA0+Orzlba8yaH7tGOyDhrVSACAR0AIggQE8nzPDxRxQajcdr34qFvbsZCPSBJCyP0PW3kDEAFmOXcAAAAASUVORK5CYII=',
            alt: 'Quintessential Duels - Only the strongest will survive the trials.'
        },
        {
            id: 2,
            date: 'April 20, 2021',
            body: '<p>Psibernetic Games is pleased to announce the release of its first game, Quintessential Duels, on Google Play! Quintessential Duels is a casual collectible card game available from <a href="https://play.google.com/store/apps/details?id=com.psiberneticgames.quintessentialduels" target="_blank" aria-label="Quintessentil Duels download link">Quintessential Duels on Google Play</a>. It is based on the mini-game Triple Triad from Final Fantasy VIII and XIV. Instead of played on a three by three grid it is played on a five by five grid. Collect over one hundred different cards and battle over thirty different decks.</p>',
            image: 'https://psiberneticgames.com/Content/CoverImage.png',
            thumb: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAACxMAAAsTAQCanBgAAAOTSURBVDhPDZNJbBtlAIXnn7H/mfHMeLzEY+ySxcZJ2iiUKFUVClUrKIUDVRHiguDEHQRISL1y5MCJAxxQbyAh9UJKOERIETQ0TtQQqSklrbM5yXhfZl//mfmx9G7v8KTvvQeWf/np5x+/zd18f+nq0rO19e3Vx29++kFju6s2xFQ6zTIMQQBFUZvNMwjhVGkyl0sxDB2GlK654MTQtu7eWV/ftYN3xWlfqT04HMZmlxZON/24AGGcCcOIJEnPdYBPhMBNCFy5MF1ZqDB0lspfuTw/f7W5GXuwtXLOfkfts+f5G+3nAyESHUUjdci6XKBZ59kluf2Mx0nOzaqGghkiCCBVeu3Vf+t1NdQyw7md1nIYxyi0m8OnQ6uheU3L0wyvp7s9FPkDVwY4ZiHdJz1RyoUBTX3z/XcEDjx5F6M1lh1Art2xH1/Mf8SSqa73xA5kgZYsMAhEO5HJYI6EGXq8UipNVjCG4M7dH9gkH3nu4OC/tLJfZIKe6rb23gtDPJLpaCiyaFrwzinlqcV8vsDziVictq2o29HBia52uq2+rtue26jXctHgZPvh/m7ioCV7DpfLwlicDELO46SX518vl8sj5giFpun6fgBqLRky0HRsXVP6vnN4dKx0esrZET7ckjIiLwhZ6UXLdH5bMwrj16V8KUZRqXQSocC2HHBvp5rNphvy6c7vv9bWNyy5off1hJiYuDBx49ocX36FE5LT+ZwA4/W9R/f/Mlr6JZ7nHdc2DJ1q/f3n09r+0UZ1ElqzE9yttxfzUmY4sHr1vjd3ffXeYcL5h8T6o4cbNOU/qakBNaOoQ1UdYIypz778ojg+obRbxy1VZYtWtsTmU5feuKymi8bqCk1zfXRhZpyxzMAIkwfti4BkPM8BAEi5Avjwk4/d6h9MDPScyA0xwiM3MSal3lrMi2zy+XJVn73yQuUluTcbBRoJC7ZtdbuNUWxpaoYaM7rXivEsQ6ZpQBBEGOHI9w1VF0R+akq0AT05xgzlRnqybNgiQm67fdZs75vmkCBiVDE7xkNMa8gNCQmQiThOhRhi3DztN8+UHIyZIUrQRP+kQWPQ6chEZ48L3YiXWDZJ3br9dfu4KhBR4AXQixAFsiSV98MkTQqjN3S1wA96fUoEudFcjF6TsYdp39Q4CVA0+Orzlba8yaH7tGOyDhrVSACAR0AIggQE8nzPDxRxQajcdr34qFvbsZCPSBJCyP0PW3kDEAFmOXcAAAAASUVORK5CYII=',
            alt: 'Quintessential Duels - Only the strongest will survive the trials.'
        }
    ]
}
export default NewsItemData;