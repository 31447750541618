import React, { useState, useEffect } from 'react';
import ImageLoader from './ImageLoader';

function ShadowMonsters() {
    const [toggleParagraph, setToggleParagraph] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <main id='content' className='container'>
            <h1>Shadow Monsters</h1>
            <div className='holder'><ImageLoader src='https://psiberneticgames.com/Content/ShadowMonsters.png' thumb='data:image.png/;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAJCAIAAAC0SDtlAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAACxMAAAsTAQCanBgAAAGZSURBVChTFZBPa9RAGIfnfWeSyf9kd1O6iUtaaD1VFv8exKs3QfwSHvwCnvwUngTBo34Hr4J4LVSpraJdaldY1q3NJpnMZDKuz/UHDz8eePr6w+L4E1IWjQsvGVthakx/OfsSFwfYSymqzaSbv1rW7nCHujGdXL+hROMGAbV5eznvlFr8OJp/+wytsmzbYqjaEgzUyzNKkfkDePL8ZXei267x8hiVUVqJP1f+btDNXImrYJgyi6/Lc8eOhFhv35/CszcfD9+9XS3mlPmOnzCf65a05qclRp0pTQ9ePCKIqAmxdX7nNjOiDIug5b5B3Lt3d5DvNcuL8xPObIvogazLMM2iNCeySpKIJRnNtiYUAMjmsN+prv71ffb1ELWy3UAKIWvBkexn4VbEO1FfnB7Tmw8e2zwyNCEsJB11vHi8XSB1i2wM6E/ya7em+3W1Xi1/AyF9D3T68BHzeBhzN2DUIa6HjoO7O6N0yBnTlRRn86UES4Kv0GnqK3jx6j2jDADDwGmadtOuN8ZidAP5r9xgqqpxuIUAdSP+ASossdJJqTqjAAAAAElFTkSuQmCC' alt='Shadow Monsters' /></div>
            <h2 className='center purple'>Coming Soon!</h2>
            {
                !toggleParagraph ?
                    <section className='toggle'>
                        <article className='white showMore'>
                            <p>The world is full of mysterious creatures called shadow monsters. Trainers capture shadow monsters and grow their skills in battle against other shadow monsters. You are a trainer and your goal is to capture them all and become the world champion in the arena.</p>
                            <p>Shadow Monsters is a Pokemon-inspired game where you capture shadow monsters and forge their skills in the heat of battle. Search the world for rare shadow monsters and battle against other trainers for the right to compete against the best trainers in the world for the title of Shadow Monster Champion.</p>
                            <p>Coming soon to Android and PC!</p>
                        </article>
                        <span className='toggleButton' onClick={() => setToggleParagraph(true)}>Show less...</span>
                    </section>
                    :
                    <section className='toggle'>
                        <article className='white showLess'>
                            <p>The world is full of mysterious creatures called shadow monsters. Trainers capture shadow monsters and grow their skills in battle against other shadow monsters. You are a trainer and your goal is to capture them all and become the world champion in the arena.</p>
                            <p>Shadow Monsters is a Pokemon-inspired game where you capture shadow monsters and forge their skills in the heat of battle. Search the world for rare shadow monsters and battle against other trainers for the right to compete against the best trainers in the world for the title of Shadow Monster Champion.</p>
                            <p>Coming soon to Android and PC!</p>
                        </article>
                        <span className='toggleButton' onClick={() => setToggleParagraph(false)}>Show more...</span>
                    </section>
            }

        </main>
    );
}

export default ShadowMonsters;