import React, { useContext } from 'react';
import { WebSiteContext } from './Website';
import { AppContext } from './App';
import Cookies from './Cookies';
import { Link } from 'react-router-dom';
import $ from 'jquery';

function Mobile() {
    const data = useContext(WebSiteContext);
    const context = useContext(AppContext);

    function scrollUp() {
        $('#mobile-nav').slideUp();
    }

    function doRepaint(params) {
        scrollUp();
        data.props.showLogin();
        //setIsLoggedIn(cookies.read('username') !== null);
    }

    function doRegister() {
        scrollUp();
        data.props.showRegister();
    }

    function LogOff() {
        scrollUp();
        Cookies.erase('username');
        Cookies.erase('userid');
        context.doLogOff();
    }

    return (
        <div id='mobile-nav'>
            <ul className='inner-nav'>
                <li><Link to='/' className='nav-button' onClick={scrollUp}>Games</Link></li>
                <li><Link to='/support' className='nav-button' onClick={scrollUp}>Support</Link></li>
                <li><Link to='/news' className='nav-button' onClick={scrollUp}>News</Link></li>
                {/* <li><Link to='/about' className='nav-button' onClick={scrollUp}>About</Link></li> */}
                <li><hr /></li>
                {
                    Cookies.read('username') === null ?
                        <span>
                            <li><button onClick={doRegister}>Register</button></li>
                            <li><button className='margin-right' onClick={doRepaint}>Login</button></li>
                        </span>
                        :
                        <span>
                            <li><button>Profile</button></li>
                            <li><button onClick={LogOff}>Log Off</button></li>
                        </span>
                }
            </ul>
        </div>);
}
export default Mobile;